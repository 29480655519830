import * as React from "react";
import { css } from "@emotion/css";

import {
  Article,
  Block,
  Button,
  Forfait,
  Tableau,
  Page,
  Section,
  SubSection,
  ImgAlign,
} from "../components";

import { h2Style, p3Style, forfaitsColBlock } from "../globalstyle";

import img01 from "../images/national-cancer-institute-l8twzt4ccvq-unsplash.jpg";
import img02 from "../images/doctor-563428_1920.jpg";

export default function Tarifs() {
  return (
    <Page tabTitle="Tarifs" pageTitle="Tarifs" id="liste-des-tarifs">
      <Section column>
        <p className="content">
          <b>Dotter.science</b> est proposé via une tarification à l’étude en
          toute transparence, vous permettant de maîtriser votre budget.
        </p>
        <div
          className={css`
            display: flex;
            height: max-content;
            justify-content: center;
            align-items: flex-start;
          `}
        >
          <div className={forfaitsColBlock}>
            <Forfait
              name="Démonstration"
              features={[
                "Accès gratuit et illimité à une étude de démonstration sur Dotter.science",
                "Testez par vous-même l'ensemble des fonctionnalités de notre logiciel dédié à la recherche clinique, de la conception des formulaires à l'export des données",
              ]}
              extra={[]}
              buttontext="Essayer la démo"
              link="https://docs.google.com/forms/d/e/1FAIpQLSfFyQN3D0wDdN9hvNsGjBSENEvJjZ9xWvONQGX5gan6cooldg/viewform"
              custom={css`
                background: #7ca7c4;
              `}
            >
              <h6>0 €</h6>
            </Forfait>
            <Forfait
              name="Forfait Liberté *"
              features={[
                "Inclusions illimitées",
                "Utilisateurs illimités",
                "Centres investigateurs illimités",
              ]}
              extra={[
                "Randomisation intégrée",
                "Serveurs certifiés HDS",
                "Assistance standard (tickets)",
              ]}
              className={css`
                border: 1px solid #aed9a1;
                background-color: #f3f3f3;
                background-image: linear-gradient(
                  rgba(255, 255, 255, 0) 50%,
                  #aed9a1 100%
                );
              `}
              buttontext="Contactez-nous !"
              link="/contact/#formulaire-de-contact"
              custom={css`
                background: #27ae60;
              `}
            >
              <span>
                <h6>
                  <b>2500 €</b> / an
                </h6>
                <p
                  className={css`
                    font-size: 18px;
                  `}
                >
                  ou
                </p>
                <p
                  className={css`
                    font-size: 28px;
                  `}
                >
                  249 € / mois
                </p>
              </span>
            </Forfait>
            <Forfait
              name="Nos options Sérénité"
              features={[
                "Configuration de  votre étude par nos équipes (eCRF « clé en main »)",
                "Study Architect (conseils lors de la conception de l'étude)",
                "Formations sur site ou à distance",
                "Assistance Premium (hotline)",
              ]}
              extra={["Développement spécifique sur devis"]}
              className={css`
                border: 1px solid #c8b2e2;

                background-color: #f3f3f3;
                background-image: linear-gradient(
                  rgba(255, 255, 255, 0) 50%,
                  #c8b2e2 100%
                );
              `}
              buttontext="En savoir plus"
              link="https://docs.google.com/forms/d/e/1FAIpQLSepqZMiJnxZs7Dk8yXDV0nshqEmaZd4TU9dBJVqOmU9DF2EzA/viewform"
            ></Forfait>
          </div>
        </div>
        <p
          className={
            "content " +
            css`
              font-family: Lato;
              font-size: 18px;
              color: #74767e;
              line-height: 1.5;
            `
          }
        >
          <em>
            * Tarif HT
            <br />
          </em>
          <em>
            Des réductions sont applicables en cas de souscriptions pour
            plusieurs études.
          </em>
        </p>
      </Section>
      <Section color="light-gray" pSize={2}>
        <SubSection>
          <ImgAlign
            src={img01}
            alt="screen_tab_dotter"
            align="center"
            imgClassName={css`
              padding: 20px;
              width: 40vw;
              @media screen and (max-width: 600px) {
                width: 90vw;
                padding: 20px 20px 0px 20px;
              }
            `}
          />
        </SubSection>
        <SubSection title="Un outil pour tous les chercheurs">
          <p className="content">
            Applicable à de nombreuses modalités d’études,
            <b> Dotter.science</b> vous permet de gérer vos protocoles de
            recherche en réduisant les coûts et les délais. Notre solution de
            stockage HDS intégrée favorise un déploiement rapide et assure
            l’intégrité de vos données.
          </p>
          <p className="content">
            Créez vos études à l’aide de l’interface ou confiez-nous la
            numérisation du CRF, contrôlez la qualité des données et
            partagez-les en temps réel. Nos équipes d’assistance sont
            disponibles à tout moment pour assurer le bon déroulement de votre
            étude.
          </p>
        </SubSection>
      </Section>
      <Section>
        <SubSection title="Détail des fonctionnalités">
          <Tableau />
        </SubSection>
      </Section>
      <Section color="green">
        <SubSection title="Organisations de recherche en santé" flex={2}>
          <p className="content">
            Pour les établissements menant ou encadrant des travaux de recherche
            de façon récurrente, nous proposons une licence sur site. Chaque
            chercheur a ainsi accès à une solution puissante, sécurisée et
            conforme.
          </p>
          <p className="content">
            <strong>Dotter.science</strong> vous permet de superviser ces études
            via des rapports mensuels d’utilisation et de communiquer de façon
            proactive avec vos chercheurs.
          </p>
        </SubSection>
        <SubSection flex={1}>
          <ImgAlign
            src={img02}
            imgClassName={css`
              width: 30vw;
              margin-right: 20px;
              @media screen and (max-width: 600px) {
                width: 90vw;
                margin-right: 0;
              }
            `}
            align="center"
          />
        </SubSection>
      </Section>
      <Block
        className={css`
          display: flex;
          width: 70vw;
        `}
      >
        <Article
          title="Avec notre système de licence sur site, économisez jusqu’à 50% par rapport à la tarification à l’étude."
          titleformat={h2Style}
        >
          <p className={p3Style}>
            <b>
              Contactez-nous pour bénéficier d’une solution adaptée à vos
              besoins.
            </b>
          </p>
        </Article>
        <Button link="/contact/#formulaire-de-contact" color="green">
          PARLONS-EN !
        </Button>
      </Block>
    </Page>
  );
}
